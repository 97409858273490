import T from 'types'
import { get, isEmpty, isUndefined } from 'lodash'
import SurveyTracker from '../interface'
import AbstractTracker from '../AbstractTracker'
import { TARGET_PLATFORM } from 'constants/config'
import { specialtyToFamily,
  storePatientAttributes,
  storeProfileAttributes,
  patientGender,
  consultationPaymentMethod,
  onboardingSpecialty,
  consultationFocusedItem,
  programOrderInstallment,
  programRecommendationDate,
} from 'utils/trackers/utils'
import { MediaConfig, MediaSetupTrackingContext } from 'helpers/hooks/useVisioConfiguration';
import { detect } from 'detect-browser'
import { CookiesHelper } from 'helpers'
import store from '../../../store'

class SegmentTracker extends AbstractTracker implements SurveyTracker {

  private static readonly LEAD_EVENT = 'lead'
  private static readonly LEAD_CONSULTATION_EVENT = 'lead_consultation'
  private static readonly LEAD_CONSULTATION_CATEGORY_EVENT = (category: string): string => `lead_consultation_${category}`
  private static readonly LEAD_ACADEMY_EVENT = 'lead_program'
  private static readonly LEAD_ACADEMY_CATEGORY_EVENT = (category: string): string => `lead_program_${category}`
  private static readonly APPOINTMENT_CHOSEN = 'appointment_chosen'
  private static readonly PURCHASE_EVENT = 'order_paid'
  private static readonly CONSULTATION_PAID_EVENT = 'order_paid_consultation'
  private static readonly CONSULTATION_PAID_FIRST_OR_REPEAT_EVENT = (isFirst: boolean): string => {
    return isFirst ? 'order_paid_consultation_first' : 'order_paid_consultation_repeat'
  }
  private static readonly CONSULTATION_PAID_CATEGORY_EVENT = (family: string, isFirst: boolean): string => {
     return isFirst ? `order_paid_consultation_first_${family}` : `order_paid_consultation_repeat_${family}`
  }
  private static readonly ACADEMY_PAID_EVENT = 'order_paid_program'
  private static readonly ACADEMY_PAID_SUBSCRIPTION_EVENT = (eventType: string): string => {
    return `order_paid_program_${eventType}`
  }
  private static readonly ACADEMY_PAID_CATEOGRY_EVENT = (eventType: string, family: string): string => {
    return `order_paid_program_${eventType}_${family}`
  }
  private static readonly BOOKING_PRE_PAYMENT_SURVEY_STARTED = 'booking_pre_payment_survey_started'
  private static readonly BOOKING_PRE_PAYMENT_SURVEY_FILLED = 'booking_pre_payment_survey_filled'
  private static readonly PHYTO_PAID_EVENT = 'order_paid_phytotherapy'
  private static readonly PHYTO_PAID_EVENT_REPETITION = (isFirst: boolean): string => {
     return isFirst ? "order_paid_phytotherapy_first" : "order_paid_phytotherapy_repeat"
  }
  private static readonly PROGRAM_PAGE_CLICKED = 'program_page_clicked'
  private static readonly ONBOARDING_PERSONAL_INFO_EDITION_MODE_ACTIVATED = 'onboarding_personal_info_edition_mode_activated'
  private static readonly ONBOARDING_PERSONAL_INFO_UPDATED = 'onboarding_personal_info_updated'
  private static readonly PRESCRIPTION_DOWNLOADED = 'prescription_downloaded'
  private static readonly CAMERA_CONFIGURATION_SUCCEEDED = 'camera_configuration_succeeded'
  private static readonly CAMERA_CONFIGURATION_FAILED = 'camera_configuration_failed'

  private static readonly MEDIA_SETUP_EVENT = 'media_setup'
  private static readonly MEDIA_SETUP_EVENT_DEVICE_MISSING = 'media_setup_device_missing'
  private static readonly MEDIA_SETUP_EVENT_SKIPPED = 'media_setup_skipped'
  private static readonly MEDIA_SETUP_EVENT_SUCCEEDED = 'media_setup_succeeded'
  private static readonly BRP_VIEWED = 'brp_viewed'
  private static readonly BRP_CLICKED = 'brp_clicked'

  config: string | undefined

  constructor() {
    super()
    this.config = process.env.REACT_APP_SEGMENT_WRITE_KEY
  }

  private enabled(): boolean {
    return (!!this.config) && (typeof window.analytics !== 'undefined')
  }

  private loaded(): boolean {
    return this.enabled && (typeof (window.analytics as any).user !== 'undefined')
  }

  public trackLead(specialty: string) {
    const patientAttributes: any = storePatientAttributes()
    const family = specialtyToFamily(specialty)
    const products = [{ product_id: specialty, price: 1, quantity: 1 }]

    const properties = {
      email: get(patientAttributes, 'email', ''),
      phone: get(patientAttributes, 'phoneNumber', ''),
      birthday: get(patientAttributes, 'birthDate', ''),
      gender: patientGender(),
      platform: TARGET_PLATFORM,
      specialty: specialty,
      products: products,
    }

    this.track(SegmentTracker.LEAD_CONSULTATION_CATEGORY_EVENT(family), properties)

    this.track(SegmentTracker.LEAD_CONSULTATION_EVENT, {
      ...properties,
      category: family
    })

    this.track(SegmentTracker.LEAD_EVENT, {
      ...properties,
      type: 'consultation',
      category: family,
    })
  }

  public trackAcademyLead(program: T.Program): void {
    const patientAttributes: any = storePatientAttributes()

    const properties = {
      email: get(patientAttributes, 'email', ''),
      platform: TARGET_PLATFORM,
      programSlug: program.slug,
    }

    this.track(SegmentTracker.LEAD_ACADEMY_CATEGORY_EVENT(program.categorySlug), properties)

    this.track(SegmentTracker.LEAD_ACADEMY_EVENT, {
      ...properties,
      categorySlug: program.categorySlug
    })

    this.track(SegmentTracker.LEAD_EVENT, {
      ...properties,
      categorySlug: program.categorySlug,
      type: 'program'
    })
  }

  public trackPageViewV2(category: string, pagePath: string) {
    this.page(category, pagePath, {
      path: pagePath,
      search: ""
    })
  }

  public trackPostPaymentInfosEdit() {
    this.track(SegmentTracker.ONBOARDING_PERSONAL_INFO_EDITION_MODE_ACTIVATED, {})
  }

  public trackPostPaymentInfosUpdate(hasDataChanged: boolean) {
    this.track(SegmentTracker.ONBOARDING_PERSONAL_INFO_UPDATED, {hasDataChanged: hasDataChanged})
  }

  public trackCreatedConsultation(consultationType: string, medium: string) {
    let patientAttributes: any = storePatientAttributes()

    if (isEmpty(patientAttributes)) {
      patientAttributes = storeProfileAttributes()
    }
    const specialty = onboardingSpecialty()
    const family = specialtyToFamily(specialty)
    const slot = medium === "chat" ? undefined : store.getState().doctors.slot
    const email = get(patientAttributes, 'email')
    const birthDate = get(patientAttributes, 'birthDate')
    const phoneNumber = get(patientAttributes, 'intlPhoneNumber') || get(patientAttributes, 'phoneNumber')
    const gender = patientGender()

    this.track(SegmentTracker.APPOINTMENT_CHOSEN, {
      consultation_type: consultationType,
      medium: medium,
      email: email,
      phone: phoneNumber,
      birthday: birthDate,
      gender: gender,
      slot: slot,
      specialty: specialty,
      family: family
    })
  }

  public trackDidPayConsultation(
    transactionId: number | any,
    transactionPrice: string,
    consultationType: string,
    medium: string,
    specialty: string,
  ) {
    let patientAttributes: any = storePatientAttributes()

    if (isEmpty(patientAttributes)) {
      patientAttributes = storeProfileAttributes()
    }

    const consultation = consultationFocusedItem()
    const email = get(patientAttributes, 'email')
    const birthDate = get(patientAttributes, 'birthDate')
    const phoneNumber = get(patientAttributes, 'intlPhoneNumber') || get(patientAttributes, 'phoneNumber')
    const gender = patientGender()
    const family = specialtyToFamily(specialty)
    const amount = parseFloat(transactionPrice)
    const consultPaymentMethod = consultationPaymentMethod()
    const isFirst: boolean = (get(consultation, 'firstOfType') === true)
    const products = [{ product_id: specialty, price: amount, quantity: 1 }]

    const properties: {
      specialty: string,
      communication_channel: string,
      platform: string,
      booking_funnel_variant?: string
    } = {
      specialty: specialty,
      communication_channel: medium,
      platform: TARGET_PLATFORM
    }

    const orderPaidProperties = {
      ...properties,
      email: email,
      phone: phoneNumber,
      order_id: transactionId,
      revenue: amount.toFixed(2),
      currency: 'EUR',
    }

    this.track(SegmentTracker.PURCHASE_EVENT, {
      consultation_specialty: specialty,
      category: family,
      email: email,
      phone: phoneNumber,
      birthday: birthDate,
      gender: gender,
      order_id: transactionId,
      revenue: amount.toFixed(2),
      currency: 'EUR',
      payment_method: consultPaymentMethod,
    })

    this.track(SegmentTracker.CONSULTATION_PAID_CATEGORY_EVENT(family, isFirst), {
      ...orderPaidProperties,
    })

    this.track(SegmentTracker.CONSULTATION_PAID_FIRST_OR_REPEAT_EVENT(isFirst), {
      ...orderPaidProperties,
      category: family,
      products: isFirst ? products : [],
    })

    this.track(SegmentTracker.CONSULTATION_PAID_EVENT, {
      ...orderPaidProperties,
      category: family,
      is_first_consultation: isFirst
    })
  }

  public trackDidPayAcademyProgram(
    transactionId: number | any,
    transactionPrice: string,
    program: T.Program,
    variant: T.Variant,
    subscriptionEvent?: string,
    orderType?: string
  ) {

    if (isUndefined(subscriptionEvent)) {
      return
    }

    const family = program.categorySlug
    const isFirst: boolean = (subscriptionEvent === "first")
    const amount = parseFloat(transactionPrice)
    const installment = programOrderInstallment(variant)
    const recommendedAt = programRecommendationDate(program.shortName)

    const properties = {
      platform: TARGET_PLATFORM,
      type: "program",
      program_slug: program.slug,
      category: family,
      installment: installment,
      revenue: amount.toFixed(2),
      currency: 'EUR',
      recommended_at: recommendedAt,
      order_id: transactionId,
      order_type: orderType,
      subscription_event: subscriptionEvent,
      first_of_type: isFirst
    }

    this.track(SegmentTracker.PURCHASE_EVENT, {
      ...properties
    })

    this.track(SegmentTracker.ACADEMY_PAID_EVENT, {
      ...properties
    })

    this.track(SegmentTracker.ACADEMY_PAID_SUBSCRIPTION_EVENT(subscriptionEvent), {
      ...properties,
    })

    this.track(SegmentTracker.ACADEMY_PAID_CATEOGRY_EVENT(subscriptionEvent, family), {
      ...properties,
    })
  }

  public trackDidPayPhytotherapy(
    revenue: string,
    orderId: string,
    orderType: string,
    firstOfType: boolean,
    products: T.SegmentPhytotherapyProductsProperties[]
  ) {
    const properties = {
      revenue: parseFloat(revenue).toFixed(2),
      currency: "EUR",
      order_id: orderId,
      platform: TARGET_PLATFORM,
      products: products
    }

    this.track(SegmentTracker.PURCHASE_EVENT, {
      ...properties,
      order_type: orderType,
      first_of_type: firstOfType
    })

    this.track(SegmentTracker.PHYTO_PAID_EVENT, {
      ...properties,
      first_of_type: firstOfType
    })

    this.track(SegmentTracker.PHYTO_PAID_EVENT_REPETITION(firstOfType), {
      ...properties
    })
  }

  public trackDidStartPrePaymentSurvey() {
    const specialty = onboardingSpecialty()
    const family = specialtyToFamily(specialty)

    this.track(SegmentTracker.BOOKING_PRE_PAYMENT_SURVEY_STARTED, {
      category: family,
      specialty: specialty,
      platform: TARGET_PLATFORM
    })
  }

  public trackDidFillPrePaymentSurvey() {
    const specialty = onboardingSpecialty()
    const family = specialtyToFamily(specialty)

    this.track(SegmentTracker.BOOKING_PRE_PAYMENT_SURVEY_FILLED, {
      category: family,
      specialty: specialty,
      platform: TARGET_PLATFORM,
    })
  }

  public trackProgramPageClicked(programSlug: string, purchasedProgram: boolean, clickOrigin: string) {
    this.track(SegmentTracker.PROGRAM_PAGE_CLICKED, {
      program_slug : programSlug,
      platform: TARGET_PLATFORM,
      purchased_program: purchasedProgram ? 'oui' : 'non',
      from: clickOrigin,
    })
  }

  public trackCameraConfigurationSucceeded() {
    this.track(SegmentTracker.CAMERA_CONFIGURATION_SUCCEEDED, {})
  }

  public trackCameraConfigurationFailed(errorName: string, errorMessage: string) {
    this.track(SegmentTracker.CAMERA_CONFIGURATION_FAILED, {
      errorName,
      errorMessage,
    })
  }

  public trackMediaSetupEvent(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {
    this.trackAnyMediaSetupEvent(SegmentTracker.MEDIA_SETUP_EVENT, config, trackingContext)
  }
  public trackMediaSetupEventDeviceMissing(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {
    this.trackAnyMediaSetupEvent(SegmentTracker.MEDIA_SETUP_EVENT_DEVICE_MISSING, config, trackingContext)
  }
  public trackMediaSetupEventSucceeded(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {
    this.trackAnyMediaSetupEvent(SegmentTracker.MEDIA_SETUP_EVENT_SUCCEEDED, config, trackingContext)
  }
  public trackMediaSetupEventSkipped(config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {
    this.trackAnyMediaSetupEvent(SegmentTracker.MEDIA_SETUP_EVENT_SKIPPED, config, trackingContext)
  }
  public trackBrpViewed() {
    this.track(SegmentTracker.BRP_VIEWED, {})
  }
  public trackBrpClicked() {
    this.track(SegmentTracker.BRP_CLICKED, {})
  }

  private trackAnyMediaSetupEvent(name: string, config: MediaConfig, trackingContext: MediaSetupTrackingContext): void {
    const browser = detect()
    const email = this.loaded() ? (window.analytics as any).user().traits().email : undefined
    const phone = this.loaded() ? (window.analytics as any).user().traits().phone : undefined

    this.track(name, {
        devices: config.devices,
        hasCamera: config.hasCamera,
        hasMic: config.hasMic,
        hasSpeaker: config.hasSpeaker,
        initializing: config.initializing,
        promptOpened: config.promptOpened,
        promptErrorName: config.promptError ? config.promptError.name : undefined,
        promptErrorMessage: config.promptError ? config.promptError.message : undefined,
        permissionForMic: config.permissionForMic,
        permissionForCamera: config.permissionForCamera,
        wizardState: config.wizardState,
        detectedBrowser: browser,
        ...trackingContext,
        platform: TARGET_PLATFORM,
      },
      {
        email,
        phone
      }
    )
  }

  public identifyUser(userId: string, traits: any) {
    if (this.enabled()) {
      window.analytics.identify(userId, traits)
    }
  }

  private track = (name: string, data: any, traits?: any): void => {
    if (this.enabled()) {
      CookiesHelper.getCookie('_fbp') && (data.fbp = CookiesHelper.getCookie('_fbp'))
      CookiesHelper.getCookie('_fbc') && (data.fbc = CookiesHelper.getCookie('_fbc'))
      window.analytics.track(name, data, traits)
    }
  }

  private page = (category: string, name: string, properties: any): void => {
    if (this.enabled()) {
      CookiesHelper.getCookie('_fbp') && (properties.fbp = CookiesHelper.getCookie('_fbp'))
      CookiesHelper.getCookie('_fbc') && (properties.fbc = CookiesHelper.getCookie('_fbc'))
      window.analytics.page(category, name, properties)
    }
  }

}

export default SegmentTracker
